<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Companies
    </div>




    <div style="overflow-y:auto; height: 800px;">
      <table class="table">
        <tr>
          <td>id</td>
          <td>name</td>
          <td>beschreibung</td>
          <td>individuelle store ids</td>
          <td>software</td>
          <td>bmd id</td>
          <td>dpw id</td>
          <td>freitext nummer</td>
          <td>gelöscht</td>
        </tr>
        <tr v-for="company in companies" v-bind:key="'c'+company.id">
          <td>{{company.id}}</td>
          <td>{{company.name}}</td>
          <td>{{company.description}}</td>
          <td>{{company.individualStoreIds}}</td>
          <td>{{company.softwareId}}</td>
          <td>{{company.bmdId}}</td>
          <td>{{company.dpwId}}</td>
          <td>{{company.companyNumber}}</td>
          <td>{{company.softDelete}}</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'superadminCompany',
  data () {
    return {
      loading: false,
      companies: [],
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
        ...mapGetters(['companyId', 'isSuperUser']),
        dateDisplay: function() {
          return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
          headerButtons() {
              let view = this;
              let state= { buttons: [] };
              /*
              state.buttons.push({ type: "block", icon: "fa-eye", click: () => { }, tooltip: 'VIEW' });
              state.buttons.push({ type: "block", icon: "fa-cloud-download-alt", click: () => { view.loadExternalUsersFromKundencenter(); }, tooltip: 'SYNC' });
              */
              return state;
          },
        timeZoneList() {
            let result = [];
            /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
            return result;
        },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.loadCompanies()
        .then(() => {
          view.companies.sort((a, b) => (a.id > b.id) ? 1 : -1);
          view.loading = false;
        });
    },

    loadCompanies: function(page = 1){
      let view = this;
      let url = '/api/superuser/company?page='+page;

      return this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
      }).then(response => {
        if (page == 1) {
          view.companies = [];
        }
        view.companies = view.companies.concat(response.data.data);
        // console.log(response);
        if (response.data.current_page < response.data.last_page) {
          return view.loadCompanies(page + 1);
        }
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px;
  text-align:left;
  float:left;
  margin-left:25px;
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
